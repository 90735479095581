import React, { useState, useEffect } from "react";

export const useTimer = (initialTime, onEnd) => {
  const [timeLeft, setTimeLeft] = useState(initialTime);
  useEffect(() => {
    if (!timeLeft) return;
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => {
        if (prevTime <= 1) {
          clearInterval(interval);
          onEnd();
          return null;
        }
        return prevTime - 1;
      });
    }, 1000);
    return () => clearInterval(interval);
  }, [timeLeft, onEnd]);

  return [timeLeft, () => setTimeLeft(initialTime)];
};
