import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseUrl, baseUrl_V2 } from "src/utils/config";
import { appendToken } from "../utils";
import { PublicInvestment, PrivateInvestment } from "./interfaces";
import { TAG_INVESTMENT_TRANSACTION_HISTORY, TAG_MARKET_INVESTMENTS } from "../middleware/api_v2";

export const TAG_PM_INVESTMENTS = "TAG_PM_INVESTMENTS";

export const marketApi = createApi({
  reducerPath: "marketApi",
  baseQuery: fetchBaseQuery({
    prepareHeaders: appendToken,
  }),
  tagTypes: [TAG_PM_INVESTMENTS, TAG_MARKET_INVESTMENTS],
  endpoints: (builder) => ({
    addNewPublicInvestment: builder.mutation<any, PublicInvestment>({
      query: (payload) => {
        const { otpCode, resendOtp, ...body } = payload;
        if (otpCode !== null)
          return {
            url: `${baseUrl_V2}/dashboard/investments?otp=${otpCode}`,
            method: "POST",
            body: body,
          };
      },
      invalidatesTags: [TAG_MARKET_INVESTMENTS]
    }),
    generateOtpPublicInvestment: builder.mutation<any, PublicInvestment>({
      query: (payload) => {
        const { otpCode = null, resendOtp, ...body } = payload;
        return {
          url: `${baseUrl_V2}/dashboard/investments?resend=${resendOtp}`,
          method: "POST",
          body: body,
        };
      },
    }),
    resendOtpPublicInvestment: builder.mutation<any, PublicInvestment>({
      query: (payload) => {
        const { otpCode = null, resendOtp, ...body } = payload;
        return {
          url: `${baseUrl_V2}/dashboard/investments?resend=${resendOtp}`,
          method: "POST",
          body: body,
        };
      },
    }),

    // Public Investment Creation
    resendCreatePublicInvestment: builder.mutation({
      query: () => {
        return {
          url: `${baseUrl_V2}/dashboard/investments?resend=true`,
          method: "POST",
        };
      },
    }),
    verifyCreatePublicInvestment: builder.mutation<any, any>({
      query: (payload) => {
        const { otp } = payload;
        return {
          url: `${baseUrl_V2}/dashboard/investments?otp=${otp}`,
          method: "POST",
          body: payload,
        };
      },
    }),
    // End of Public Investment Creation

    addNewPrivateInvestment: builder.mutation<any, PrivateInvestment>({
      query: (payload) => {
        const { otpCode, resendOtp, ...body } = payload;
        if (otpCode)
          return {
            url: `${baseUrl}/private-market/investemts?otp=${otpCode}`,
            method: "POST",
            body: body,
          };
      },
      invalidatesTags: [TAG_PM_INVESTMENTS],
    }),
    generateOtpPrivateInvestment: builder.mutation<any, PrivateInvestment>({
      query: (payload) => {
        const { otpCode = null, resendOtp = false, ...body } = payload;
        return {
          url: `${baseUrl}/private-market/investemts`,
          method: "POST",
          body: body,
        };
      },
    }),
    resendOtpPrivateInvestment: builder.mutation<any, PrivateInvestment>({
      query: (payload) => {
        const { otpCode = null, resendOtp, ...body } = payload;
        return {
          url: `${baseUrl}/private-market/investemts?resend=${resendOtp}`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: [TAG_PM_INVESTMENTS],
    }),
    getAssetsNavs: builder.query({
      query: () => ({
        url: `${baseUrl_V2}/dashboard/reports/assets`,
        method: "GET",
      }),
    }),

    cancelPMInvestment: builder.mutation<any, any>({
      query: ({ id }) => ({
        url: `${baseUrl}/private-market/${id}/cancel?resend=true`,
        method: "POST",
      }),
    }),

    cancelPMInvestmentOTP: builder.mutation<any, any>({
      query: ({ id, otp }) => ({
        url: `${baseUrl}/private-market/${id}/cancel?otp=${otp}`,
        method: "POST",
      }),
      invalidatesTags: [TAG_PM_INVESTMENTS],
    }),
    getPMInvestments: builder.query<any, any>({
      query: ({ limit }: { limit: number }) => ({
        url: `${baseUrl}/private-market/investemts`,
        params: { limit },
      }),
      providesTags: [TAG_PM_INVESTMENTS],
    }),
  }),
});

export const {
  useGenerateOtpPublicInvestmentMutation,
  useResendOtpPublicInvestmentMutation,
  useAddNewPublicInvestmentMutation,
  useAddNewPrivateInvestmentMutation,
  useGenerateOtpPrivateInvestmentMutation,
  useResendOtpPrivateInvestmentMutation,
  useGetAssetsNavsQuery,
  useCancelPMInvestmentMutation,
  useCancelPMInvestmentOTPMutation,
  useGetPMInvestmentsQuery,
  useResendCreatePublicInvestmentMutation,
  useVerifyCreatePublicInvestmentMutation,
} = marketApi;
