import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateContributionField } from "src/store/Goal-Based/ContributionAmountSlice";
import { updateGoalField } from "src/store/Goal-Based/GoalAmountSlice";
import { useGetRecommendedSmartfoliosQuery } from "src/store/middleware/api_v2";
import { RootStateType } from "src/store/rootReducer";
import { theme } from "src/theme/theme";
import styled from "styled-components";

interface RadioOption {
  label: string;
  value: string;
}

const RadioListContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-wrap: wrap;
  margin-bottom: 1.2rem;
`;

const CheckedCircle = styled.div`
  width: 16px;
  height: 16px;
  border: 2px solid green;
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const RadioButton = styled.button<{ isChecked: boolean }>`
  display: flex;
  align-items: center;
  padding: 7px 14px;
  border: 1px solid
    ${({ isChecked }) => (isChecked ? theme.colors.primary[500] : "#ccc")};
  border-radius: 10px;
  transition: all 0.3s ease;
  cursor: pointer;
  background-color: ${({ isChecked }) =>
    isChecked ? theme.colors.primary[25] : "white"};
  color: ${({ isChecked }) => (isChecked ? theme.colors.primary[500] : "#555")};
`;

const CheckedIcon = styled.span`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-45%, -45%);
`;

const EmptyCircle = styled.span<{ isChecked: boolean }>`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 2px solid
    ${({ isChecked }) => (isChecked ? theme.colors.primary[500] : "#555")};
  border-radius: 50%;
  margin-right: 7px;
`;

interface RadioListProps {
  options: RadioOption[];
  refetch: () => void;
}

const RadioList: React.FC<RadioListProps> = ({ options, refetch }) => {
  const [selectedValue, setSelectedValue] = React.useState<string | null>(null);
  const currentMenu = useSelector(
    (state: RootStateType) => state.menuReducer.currentState,
  );
  const goalAmount_objective = useSelector(
    (state: RootStateType) => state.GoalAmountReducer.objective,
  );
  const contribution_objective = useSelector(
    (state: RootStateType) => state.ContributionAmountReducer.objective,
  );
  const dispatch = useDispatch();

  const handleChange = (value: string) => {
    setSelectedValue(value);
    dispatch(updateGoalField({ name: "objective", value }));
    dispatch(updateContributionField({ name: "objective", value }));
    refetch();
  };
  useEffect(() => {
    if (currentMenu === "GoalAmount") {
      setSelectedValue(goalAmount_objective);
    } else {
      setSelectedValue(contribution_objective);
    }
  }, [currentMenu]);
  return (
    <RadioListContainer>
      {options.map((option) => (
        <RadioButton
          key={option.value}
          isChecked={option.value === selectedValue}
          onClick={() => handleChange(option.value)}
        >
          <>
            <EmptyCircle isChecked={option.value === selectedValue}>
              {option.value === selectedValue && (
                <CheckedIcon>&#10004;</CheckedIcon>
              )}
            </EmptyCircle>
            <span style={{ fontSize: "1.2em", fontWeight: "600" }}>
              {option.label}
            </span>
          </>
        </RadioButton>
      ))}
    </RadioListContainer>
  );
};

export default RadioList;
