import { Modal } from "antd";
import { Flex } from "src/components/Flex";
import styled from "styled-components";

export const Wrapper = styled(Modal)`
  & .ant-modal-content {
    border-radius: 8px;
  }
`;

export const Description = styled.span`
  font-size: 1em;
  opacity: 0.5;
  text-align: center;
  margin-top: 0.8rem;
`;

export const Content = styled(Flex)`
  align-items: center;
  width: 100%;
  max-width: 700px;
  margin: 1rem 0.8rem;
  background-color: #f7f9fb;
  border-radius: 8px;
  padding: 1.8rem 2rem;
`;

export const List = styled(Flex)`
  flex-direction: column;
  gap: 0.2rem;
  margin: 0.8rem auto;
  width: 100%;
  max-width: 500px;
`;

export const Item = styled(Flex)`
  justify-content: space-between;

  & > *:first-child {
    font-size: 1rem;
    font-weight: normal;
    opacity: 0.8;
  }

  & > *:last-child {
    font-size: 1rem;
  }
`;

export const SubTitle = styled.span`
  color: rgb(13, 75, 168);
  text-align: left;
  font-size: 1rem;
  margin: 1rem 0 0;
  font-weight: normal;
`;
