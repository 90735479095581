import { message } from "antd";
import { useMutation } from "react-query";
import axiosInstance from "src/utils/axiosInstance";
import { BuyI } from "../utils/interfaces";

const BUY_STOCK_ORDER_ENDPOINT = '/dashboard/direct-orders/buy'

type BuyFormData = {
    payload: BuyI;
    otpCode: string | null;
};

export const buyTicker = async ({ payload, otpCode }: BuyFormData) => {
    if (otpCode === null)
        await axiosInstance.post(`${BUY_STOCK_ORDER_ENDPOINT}?resend=true`, payload, { baseURL: 'v2' });
    else
        await axiosInstance.post(`${BUY_STOCK_ORDER_ENDPOINT}?otp=${otpCode}`, payload, { baseURL: 'v2' });

};

export const useBuyTickerOrder = () => {
    return useMutation(buyTicker, {
        onSuccess: () => {
        },
        onError: (error) => {
        },
    });
}