import { fetchBaseQuery, createApi } from "@reduxjs/toolkit/query/react";
import { baseUrl } from "src/utils/config";
import {
  ContributionData,
  Factsheet,
  GoalData,
  SolveDurationData,
  SolveInitialData,
  PlanConfirmationPayload,
  Recommendations,
  Securities,
  SelfMadeSmartfolio,
  Smartfolio,
  Plan,
  SelfMadeSmartfolios,
} from "src/store/Goal-Based/goal";
import { GoalStats, EditAssetClassesPayload } from "./goal";
import { appendToken } from "../utils";

export const goalBasedApi = createApi({
  reducerPath: "goalBasedApi",
  baseQuery: fetchBaseQuery({
    baseUrl,
    prepareHeaders: appendToken,
  }),
  tagTypes: ["Self-Made", "SmartfolioInfo", "lean"],
  endpoints: (builder) => ({
    getGoalAmountStats: builder.query<
      GoalStats | Plan,
      { input: GoalData; option?: "true" | "false"; id?: string }
    >({
      query: ({ input, option, id }) => {
        const obj = {};
        for (const key in input) {
          if (input[key] !== null) {
            obj[key] = input[key];
          }
        }
        return {
          url: `/public-market/goal-based-investment/goal-amount/?save_collection=${
            option ?? "false"
          }`,
          method: "POST",
          body: !option ? obj : { ...obj, smartfolioId: id },
        };
      },
    }),
    getSolveInitialStats: builder.query<
      GoalStats | Plan,
      { input: SolveInitialData; option?: "true" | "false"; id?: string }
    >({
      query: ({ input, option, id }) => ({
        url: `/public-market/goal-based-investment/initial-amount/?save_collection=${
          option ?? "false"
        }`,
        method: "POST",
        body: !option ? input : { ...input, smartfolioId: id },
      }),
    }),
    getSolveDurationStats: builder.query<
      GoalStats | Plan,
      { input: SolveDurationData; option?: "true" | "false"; id?: string }
    >({
      query: ({ input, option, id }) => ({
        url: `/public-market/goal-based-investment/based-on-duration/?save_collection=${
          option ?? "false"
        }`,
        method: "POST",
        body: !option ? input : { ...input, smartfolioId: id },
      }),
    }),
    getContributionAmountStats: builder.query<
      GoalStats | Plan,
      { input: ContributionData; option?: "true" | "false"; id?: string }
    >({
      query: ({ input, option, id }) => {
        const obj = {};
        for (const key in input) {
          if (input[key] !== null) {
            obj[key] = input[key];
          }
        }
        return {
          url: `/public-market/goal-based-investment/contribution-amount/?save_collection=${
            option ?? "false"
          }`,
          method: "POST",
          body: !option ? obj : { ...obj, smartfolioId: id },
        };
      },
    }),
    getBestSmartfolios: builder.query<Recommendations, void>({
      query: () => ({
        url: "/public-market/goal-based-investment/suggested-smartfolio",
        method: "GET",
      }),
    }),
    getSecurities: builder.query<Securities, void>({
      query: () => ({
        url: "/public-market/goal-based-investment/tickers",
        method: "GET",
      }),
      keepUnusedDataFor: Infinity,
    }),
    buildSmartfolio: builder.mutation<any, SelfMadeSmartfolio>({
      query: (smartfolioData) => ({
        url: "/public-market/goal-based-investment/build-smartfolio",
        method: "POST",
        body: smartfolioData,
      }),
      invalidatesTags: ["Self-Made"],
    }),
    editAssetClasses: builder.mutation<
      any,
      { payload: EditAssetClassesPayload; smartfolioId: string }
    >({
      query: ({ payload, smartfolioId }) => ({
        url: `/public-market/goal-based-investment/smartfolio/${smartfolioId}/asset-classes`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["SmartfolioInfo"],
    }),
    getSelfMade: builder.query<SelfMadeSmartfolios, void>({
      query: () => ({
        url: "/public-market/goal-based-investment/self-made-smartfolio",
        method: "GET",
      }),
      providesTags: ["Self-Made"],
    }),
    getSmartfolio: builder.query<Smartfolio, string>({
      query: (planId) => ({
        url: `/public-market/goal-based-investment/${planId}/smartfolio`,
        method: "GET",
      }),
      providesTags: ["SmartfolioInfo"],
    }),
    getFactsheet: builder.query<Factsheet, string>({
      query: (planId) => {
        const query = new URLSearchParams({
          planId,
          fileFormat: "json",
        });

        return {
          url: `/public-market/goal-based-investment/factsheet/?${query}`,
          method: "GET",
        };
      },
    }),
    getNAV: builder.query<any, string>({
      query: (planId) => ({
        url: `/public-market/goal-based-investment/${planId}/nav`,
        method: "GET",
      }),
    }),
    getPerformance: builder.query<any, string>({
      query: (planId) => ({
        url: `/public-market/goal-based-investment/${planId}/performance`,
        method: "GET",
      }),
    }),
    getGoalBasedPlan: builder.query<any, string>({
      query: (planId) => ({
        url: `/public-market/goal-based-investment/${planId}`,
        method: "GET",
      }),
    }),
    confirmPlan: builder.mutation<any, PlanConfirmationPayload>({
      query: (payload) => {
        const { otpCode, resendOtp, riskSolution, planId } = payload;
        if (otpCode) {
          return {
            url: `/public-market/goal-based-investment/${planId}/confirm?otp=${otpCode}`,
            method: "POST",
            body: {
              riskSolution,
            },
          };
        } else if (resendOtp === true) {
          return {
            url: `/public-market/goal-based-investment/${planId}/confirm?resend=${resendOtp}`,
            method: "POST",
            body: {
              riskSolution,
            },
          };
        } else
          return {
            url: `/public-market/goal-based-investment/${planId}/confirm`,
            method: "POST",
            body: {
              riskSolution,
            },
          };
      },
    }),
    editBasket: builder.mutation<
      any,
      { payload: SelfMadeSmartfolio; basketId: string }
    >({
      query: ({ payload, basketId }) => ({
        url: `/public-market/goal-based-investment/basket/${basketId}`,
        method: "PUT",
        body: payload,
      }),
      invalidatesTags: ["SmartfolioInfo"],
    }),
  }),
});

export const {
  useGetGoalAmountStatsQuery,
  useGetContributionAmountStatsQuery,
  useGetSolveInitialStatsQuery,
  useGetSolveDurationStatsQuery,
  useGetBestSmartfoliosQuery,
  useBuildSmartfolioMutation,
  useGetSecuritiesQuery,
  useGetSelfMadeQuery,
  useGetSmartfolioQuery,
  useGetFactsheetQuery,
  useGetNAVQuery,
  useGetPerformanceQuery,
  useGetGoalBasedPlanQuery,
  useConfirmPlanMutation,
  useEditAssetClassesMutation,
  useEditBasketMutation,
} = goalBasedApi;
