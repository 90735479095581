// Other function has some issues which is fixed by this one
// make sure to use this fn in future so we can remove the old one

function commaNumbersFormat(number, prefix = "", suffix = "") {
  if (typeof number !== "number" || isNaN(number)) {
    return 0;
  }

  if (typeof prefix !== "string" || typeof suffix !== "string") {
    return 0;
  }

  let parts = number.toString().split(".");

  let integerPart = parseInt(parts[0], 10).toLocaleString();

  let decimalPart = parts[1] ? parts[1].slice(0, 2) : "";

  while (decimalPart.length < 2) {
    decimalPart += "0";
  }

  decimalPart = "." + decimalPart;

  return prefix + integerPart + decimalPart + suffix;
}

export default commaNumbersFormat;
