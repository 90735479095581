import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootStateType } from "../rootReducer";

interface MarketState {
  group: string;
  marketName?: string;
  market?: string;
  layers: Array<{ key: string; value: boolean }>;
  classes: Array<{ key: string; value: boolean }>;
  capitalProtection: Array<{ key: string; value: boolean }>;
  underlyingAsset: Array<{ key: string; value: boolean }>;
  selectedSmartfolioId: number;
  issuerRating: Array<{ key: string; value: boolean }>;
  payoutStructure: Array<{ key: string; value: boolean }>;
  maturities: Array<{ key: string; value: boolean }>;
  shariaaCompliances: Array<{ key: string; value: boolean }>;
}

const initialState: MarketState = {
  group: "",
  marketName: "",
  market: null,
  selectedSmartfolioId: 0,
  layers: [],
  classes: [],
  capitalProtection: [],
  underlyingAsset: [],
  issuerRating: [],
  payoutStructure: [],
  maturities: [],
  shariaaCompliances: [],
};

const marketSlice = createSlice({
  name: "markets",
  initialState,
  reducers: {
    setSmartfolioId: (state, action: PayloadAction<number>) => {
      state.selectedSmartfolioId = action.payload;
    },
    setMarketState: (state, action: PayloadAction<string>) => {
      state.market = action.payload;
      state.classes = [];
      state.layers = [];
      state.capitalProtection= [];
      state.underlyingAsset= [];
      state.issuerRating= [];
      state.payoutStructure= [];
      state.maturities= [];
      state.shariaaCompliances= []
    },
    setMarketName: (state, action: PayloadAction<string>) => {
      state.marketName = action.payload;
    },
    setGroupState: (state, action: PayloadAction<string>) => {
      state.group = action.payload;
    },
    setLayer: (
      state,
      action: PayloadAction<{ key: string; value: boolean }>,
    ) => {
      const { key, value } = action.payload;
      const index = state.layers.findIndex((l) => l.key === key);
      if (index !== -1) {
        state.layers[index].value = value;
      } else {
        state.layers.push({ key, value });
      }
    },
    setClass: (
      state,
      action: PayloadAction<{ key: string; value: boolean }>,
    ) => {
      const { key, value } = action.payload;
      const index = state.classes.findIndex((c) => c.key === key);
      if (index !== -1) {
        state.classes[index].value = value;
      } else {
        state.classes.push({ key, value });
      }
    },
    toggleLayer: (state, action: PayloadAction<string>) => {
      const key = action.payload;
      const layer = state.layers.find((l) => l.key === key);
      if (layer) {
        layer.value = !layer.value;
      } else {
        state.layers.push({ key, value: true });
      }
    },
    toggleClass: (state, action: PayloadAction<string>) => {
      const key = action.payload;
      const clazz = state.classes.find((c) => c.key === key);
      if (clazz) {
        clazz.value = !clazz.value;
      } else {
        state.classes.push({ key, value: true });
      }
    },
    toggleCapitalProtection: (state, action: PayloadAction<string>) => {
      const key = action.payload;
      const clazz = state.capitalProtection.find((cp) => cp.key === key);
      if (clazz) {
        clazz.value = !clazz.value;
      } else {
        state.capitalProtection.push({ key, value: true });
      }
    },
    toggleUnderlyingAsset: (state, action: PayloadAction<string>) => {
      const key = action.payload;
      const clazz = state.underlyingAsset.find((ua) => ua.key === key);
      if (clazz) {
        clazz.value = !clazz.value;
      } else {
        state.underlyingAsset.push({ key, value: true });
      }
    },
    toggleIssuerRating: (state, action: PayloadAction<string>) => {
      const key = action.payload;
      const issuer = state.issuerRating.find((i) => i.key === key);

      if (issuer) {
        issuer.value = !issuer.value;
      } else {
        state.issuerRating.push({ key, value: true });
      }
    },
    togglePayoutStructure: (state, action: PayloadAction<string>) => {
      const key = action.payload;
      const payout = state.payoutStructure.find((p) => p.key === key);

      if (payout) {
        payout.value = !payout.value;
      } else {
        state.payoutStructure.push({ key, value: true });
      }
    },
    toggleMaturity: (state, action: PayloadAction<string>) => {
      const key = action.payload;
      const maturity = state.maturities.find((m) => m.key === key);

      if (maturity) {
        maturity.value = !maturity.value;
      } else {
        state.maturities.push({ key, value: true });
      }
    },
    toggleShariaa: (state, action: PayloadAction<string>) => {
      const key = action.payload;
      const shariaa = state.shariaaCompliances.find((s) => s.key === key);

      if (shariaa) {
        shariaa.value = !shariaa.value;
      } else {
        state.shariaaCompliances.push({ key, value: true });
      }
    }
  },
});

export const selectMarket = (state: RootStateType) =>
  state.marketReducer.market;

export const selectMarketName = (state: RootStateType) =>
  state.marketReducer.marketName;

export const selectGroup = (state: RootStateType) => state.marketReducer.group;

export const selectSelectedSmartfolioId = (state: RootStateType) =>
  state.marketReducer.selectedSmartfolioId;

export const selectLayers = (state: RootStateType) =>
  state.marketReducer.layers;

export const selectClasses = (state: RootStateType) =>
  state.marketReducer.classes;

export const selectCapitalProtection = (state: RootStateType) =>
  state.marketReducer.capitalProtection;

export const selectUnderlyingAsset = (state: RootStateType) =>
  state.marketReducer.underlyingAsset;

export const selectIssuerRating = (state: RootStateType) =>
  state.marketReducer.issuerRating;

export const selectPayoutStructure = (state: RootStateType) =>
  state.marketReducer.payoutStructure;

export const selectMaturities = (state: RootStateType) =>
  state.marketReducer.maturities;

export const selectShariaa = (state: RootStateType) =>
  state.marketReducer.shariaaCompliances;


export const selectActiveAssets = (state: RootStateType) =>
  state.marketReducer.classes
    .filter((item) => item.value)
    .map((item) => item.key);

export const selectActiveLayers = (state: RootStateType) =>
  state.marketReducer.layers
    .filter((item) => item.value)
    .map((item) => item.key);

export const selectActiveCapitalProtection = (state: RootStateType) =>
    state.marketReducer.capitalProtection
      .filter((item) => item.value)
      .map((item) => item.key);

export const selectActiveUnderlyingAsset = (state: RootStateType) =>
      state.marketReducer.underlyingAsset
        .filter((item) => item.value)
        .map((item) => item.key);  

export const selectActiveIssuers = (state: RootStateType) =>
  state.marketReducer.issuerRating
    .filter((item) => item.value)
    .map((item) => item.key);

export const selectActivePayouts = (state: RootStateType) =>
  state.marketReducer.payoutStructure
    .filter((item) => item.value)
    .map((item) => item.key);

export const selectActiveMaturities = (state: RootStateType) =>
  state.marketReducer.maturities
    .filter((item) => item.value)
      .map((item) => item.key);

export const selectActiveShariaa = (state: RootStateType) =>
  state.marketReducer.shariaaCompliances
    .filter((item) => item.value)
    .map((item) => item.key);

export const selectAllActiveKeys = (state: RootStateType) => {
  const activeAssets = selectActiveAssets(state);
  const activeLayers = selectActiveLayers(state);
  const activeCapital = selectActiveCapitalProtection(state);
  const activeUnderlying = selectActiveUnderlyingAsset(state);
  const activeIssuers = selectActiveIssuers(state);
  const activePayouts = selectActivePayouts(state);
  const activeMaturities = selectActiveMaturities(state);
  const activeShariaas = selectActiveShariaa(state);
  return [...activeAssets, ...activeLayers, ...activeIssuers, ...activePayouts,
    ...activeMaturities,
    ...activeShariaas, ...activeCapital,
    ...activeUnderlying];
};

export const {
  toggleClass,
  toggleLayer,
  toggleIssuerRating,
  toggleCapitalProtection,
  toggleUnderlyingAsset,
  togglePayoutStructure,
  toggleMaturity,
  toggleShariaa,
  setMarketState,
  setLayer,
  setClass,
  setSmartfolioId,
  setGroupState,
  setMarketName
} = marketSlice.actions;
export default marketSlice.reducer;
