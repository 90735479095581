import { useQuery } from "react-query";
import { ISmartfolio } from "src/interfaces/ClassifiedSmartfolios";
import axios from "src/utils/axiosInstance";
import { baseUrl } from "src/utils/config";

// const useSmartfoliosPerformance = <T>(
//   clientType: 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | string,
// ) => {
//   return useQuery<T[]>(
//     "/Smartfolios/performance",
//     async () =>
//       (
//         await axios.get(
//           `${baseUrl}/trackers/performance?clientType=${clientType}`,
//         )
//       ).data,
//     {
//       refetchInterval: 3600000, // one time per hour
//       refetchOnWindowFocus: false,
//     },
//   );
// };

const useSmartfoliosAll = () =>
  useQuery<ISmartfolio[]>(
    "/Smartfolios/all",
    // async () => (await axios.get(`${baseUrl}/trackers/?structure=flat`)).data,
    {
      staleTime: 1800000,
      refetchOnWindowFocus: false,
    },
  );

const useSmartfoliostest = () =>
  useQuery<ISmartfolio[]>(
    "/products/public",
    async () => (await axios.get(`${baseUrl}/products/public`)).data,
    {
      staleTime: 1800000,
      refetchOnWindowFocus: false,
    },
  );

export const useSmartfolio = (smartfolioId: string) => {
  return useQuery<ISmartfolio>(
    ["/Smartfolios/", smartfolioId],
    // async () => {
    //   if (!smartfolioId) return undefined;
    //   return (await axios.get(`${baseUrl}/trackers/${smartfolioId}`)).data;
    // },
    {
      staleTime: 1800000,
      refetchOnWindowFocus: false,
    },
  );
};

const useSmartfoliosSecurities = <T>() =>
  useQuery<T[]>(
    "/Smartfolios/Securities",
    // async () => (await axios.get(`${baseUrl}/trackers/all/securities`)).data,
    {
      staleTime: 1800000,
      refetchOnWindowFocus: false,
    },
  );

const usePrivateSmartfolios = <T>() =>
  useQuery<T[]>(
    "PrivateMarket/Smartfolios",
    async () =>
      (await axios.get(`${baseUrl}/private-market/products/private/all`)).data,
    {
      staleTime: 1800000,
      refetchOnWindowFocus: false,
    },
  );

export {
  useSmartfoliosAll,
  useSmartfoliosSecurities,
  usePrivateSmartfolios,
  useSmartfoliostest,
};
// export default useSmartfoliosPerformance;
