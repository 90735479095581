import React, { FC, useState, useEffect } from "react";
import * as Styled from "./ConfirmationPopup.css";
import { Flex } from "src/components/Flex";
import { Header } from "src/components/Invest/styles/invest";
import { Button, Col, ModalProps, Row, Typography, message } from "antd";
import InvestButton from "src/components/Button";
import Text from "antd/lib/typography/Text";
import { AutopilotSwitch } from "../AutopilotSwitch/AutopilotSwitch";
import { formatMoney } from "src/utils/formatMoney";
import dayjs from "dayjs";
import { useConfirmPlanMutation } from "src/store/Goal-Based/goalBasedApi";
import Invest from "src/components/Invest";
import checkIcon from "src/assets/check.svg";
import { ButtonTheme } from "src/components/Button/ButtonTheme";
import NiceModal from "@ebay/nice-modal-react";
import Otp from "src/components/Modals/Otp";
import { errorWrapper } from "src/action";
import commaNumbersFormat from "src/utils/commaNumbersFormat"

type ConfirmationPopupProps = ModalProps & {
  data: Record<string, any>;
  planId: string;
  setConfirmationPopupVisible: React.Dispatch<React.SetStateAction<boolean>>;
};

const contributionFrequency = {
  1: "Monthly",
  3: "Quarterly",
  6: "Semi Yearly",
  12: "Yearly",
};

const onError = (type: "generate" | "resend" | "verify") => {
  if (type === "verify")
    message.error("The OTP code you entered is not correct!");
  if (type === "resend") message.error("Failed to resend new OTP code!");
  if (type === "generate")
    message.error("Something went wrong, failed to confirm investment!");
};

const onSuccess = (type: "resend" | "verify") => {
  if (type === "verify")
    message.success(
      "Congratulations! Your investment plan has been successfully created.",
    );
  if (type === "resend")
    message.success("A new OTP Code has been sent to you!");
};

const periodFormater = (months) => {
  const years = Math.floor(months / 12);
  const remainingMonths = months % 12;

  const response = [];
  if (years > 0) response.push(`${years} Years`);
  response.push(`${remainingMonths} Months`);

  return response.join(" & ");
};

export const OTPModal = ({
  isModalVisible,
  setModalVisible,
  autopilot,
  planId,
  setConfirmationPopupVisible,
}) => {
  const [otpValue, setOtpValue] = useState("");
  const riskSolution = autopilot === "On" ? "autopilot" : "fullexposure";
  const [confirmPlan] = useConfirmPlanMutation();
  const [isResendLoading, setIsResendLoading] = useState(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState(false);

  const close = () => {
    setModalVisible(false);
    setConfirmationPopupVisible(false);
  };

  return (
    <Invest.Modal
      title="OTP Verification"
      handleCancel={() => setModalVisible(false)}
      isModalVisible={isModalVisible}
      handleOk={() => console.log("OK")}
      footer={[
        <Invest.Action key="customFooter">
          <InvestButton
            key="back"
            outline={true}
            width="100%"
            onClick={async () => {
              try {
                setIsResendLoading(true);
                await confirmPlan({
                  planId,
                  riskSolution,
                  resendOtp: true,
                }).unwrap();
                setIsResendLoading(false);
                onSuccess("resend");
              } catch (error) {
                onError("resend");
                setIsResendLoading(false);
              }
            }}
            loading={isResendLoading ? "loading" : undefined}
          >
            Resend OTP
          </InvestButton>
          <InvestButton
            key="submit"
            onClick={async () => {
              try {
                setIsConfirmLoading(true);
                await confirmPlan({
                  planId,
                  riskSolution,
                  otpCode: otpValue.replace(/\s/g, ""),
                }).unwrap();
                setIsConfirmLoading(false);
                setTimeout(() => {
                  close();
                  setOtpValue("");
                }, 300);
                onSuccess("verify");
              } catch (error) {
                onError("verify");
                setIsConfirmLoading(false);
              }
            }}
            width="100%"
            type="submit"
            loading={isConfirmLoading ? "loading" : undefined}
            disabled={
              !(
                otpValue !== undefined &&
                /^\d+$/.test(otpValue) &&
                otpValue.length === 6
              )
            }
          >
            <Invest.ButtonContainer>
              Verify
              <Invest.Icon size="20px" color="white" src={checkIcon} />
            </Invest.ButtonContainer>
          </InvestButton>
        </Invest.Action>,
      ]}
    >
      <Invest.OTP
        error={null}
        onChange={({ target }) => {
          setOtpValue(target.value.trim());
        }}
        onBlur={console.log}
        value={otpValue}
      />
    </Invest.Modal>
  );
};

export const ConfirmationPopup: FC<ConfirmationPopupProps> = ({
  data,
  planId,
  setConfirmationPopupVisible,
  ...modalProps
}) => {
  const [autopilot, setAutopilot] = useState("Off");
  const [confirmPlan, { isLoading }] = useConfirmPlanMutation();

  const buttons = [
    {
      title: "Cancel",
      handler: modalProps.onCancel,
    },
    {
      title: "Confirm",
      handler: async () => {
        const riskSolution = "autopilot";
        try {
          await confirmPlan({
            planId,
            riskSolution,
            resendOtp: false,
          }).unwrap();
          // setIsOtpModalVisible(true);
          NiceModal.show(Otp, { verify, resend, onSuccess });
        } catch (error) {
          onError("generate");
          setConfirmationPopupVisible(false);
        }
      },
      primary: true,
      isLoading: isLoading,
    },
  ];
  const onSuccess = async () => {
    setConfirmationPopupVisible(false);
    message.success(
      "Congratulations! Your investment plan has been successfully created.",
    );
  };
  const verify = async (otp) => {
    const riskSolution = "autopilot";
    try {
      await confirmPlan({
        otpCode: otp,
        planId,
        riskSolution,
        resendOtp: false,
      }).unwrap();
    } catch (error) {
      throw errorWrapper(error);
    }
  };
  const resend = async () => {
    const riskSolution = "autopilot";
    try {
      await confirmPlan({
        planId,
        riskSolution,
        resendOtp: true,
      }).unwrap();
      message.success("A new OTP Code has been sent to you!");
    } catch (error) {
      throw errorWrapper(error);
    }
  };

  const info = [
    {
      title: "Investment initial Amount",
      value: `$${formatMoney(data.initial_amount)}`,
    },
    {
      title: "Final / Goal Amount",
      value: `$${formatMoney(data.goal_amount)}`,
    },
    { title: "Investment Duration", value: periodFormater(data.period) },
    {
      title: "Contribution frequency",
      value: contributionFrequency[data.contribution_type ?? 0],
    },
    {
      title: "Start Date",
      value: dayjs(data.start_date).format("MMMM D, YYYY"),
    },
    { title: "End Date", value: dayjs(data.end_date).format("MMMM D, YYYY") },
    {
      title: "Goal Contribution",
      value: `$${formatMoney(data.contribution_amount)}`,
    },
    {
      title: `Initial Management fee ${commaNumbersFormat(data.smartfolio_managment_fees * 100)}% `,
      value: `$${formatMoney(data.initial_amount * data.smartfolio_managment_fees)}`,
    },
    {
      title: `Management fee per contribution ${commaNumbersFormat(data.smartfolio_managment_fees * 100)}% `,
      value: `$${formatMoney(data.contribution_amount * data.smartfolio_managment_fees)}`,
    },

  ];

  const [isOtpModalVisible, setIsOtpModalVisible] = useState(false);

  return (
    <Styled.Wrapper
      {...modalProps}
      onOk={null}
      footer={null}
      closable={false}
      width={1000}
    >
      <OTPModal
        isModalVisible={isOtpModalVisible}
        setModalVisible={setIsOtpModalVisible}
        autopilot={autopilot}
        planId={planId}
        setConfirmationPopupVisible={setConfirmationPopupVisible}
      />
      <Flex flexDirection="column" alignItems="center">
        <Header>Order Summary</Header>
        <Styled.Description>
          {process.env.REACT_APP_BANK_NAME || ""} will add the corresponding
          Management Fees.
          <br />
          We will send you a copy of this summary via email
        </Styled.Description>
        <Styled.Content>
          <Typography.Title
            style={{ textAlign: "center", width: "100%" }}
            level={4}
          >
            Global Stocks
            <Styled.List>
              {info.map(({ title, value }) => (
                <Styled.Item>
                  <Text>{title}</Text>
                  <Text>{value}</Text>
                </Styled.Item>
              ))}
            </Styled.List>
          </Typography.Title>
        </Styled.Content>
        <Row justify="center" gutter={[16, 16]} style={{ width: "100%" }}>
          {buttons.map(({ title, handler, primary, isLoading }) => (
            <Col lg={4}>
              <ButtonTheme
                block
                style={{ borderRadius: 8 }}
                size={"large"}
                primary={primary}
                onClick={handler}
                loading={isLoading}
              >
                {title}
              </ButtonTheme>
            </Col>
          ))}
        </Row>
      </Flex>
    </Styled.Wrapper>
  );
};
