import React, { useState } from "react";
import { SelectValue } from "antd/es/select";
import InputMask from "react-input-mask";
import moment from "moment";
import ExitSuccessIcon from "src/assets/exit-success.svg";
import OrderSuccess from "src/assets/OrderSuccess.svg";
import CheckMark from "src/assets/CheckMark.svg";
import { getCurrentLayerPercentageColor } from "src/pages/Invest/PublicMarket/utils/riskProfile";
import { numberWithCommas } from "src/utils/numberWithCommas";
import Disclaimer from "src/components/Disclaimer";
import { DisclaimerContainer } from "src/pages/Invest/PublicMarket/components/Disclaimer";

import {
  RadioElement,
  Container,
  Icon,
  Form,
  Info,
  InputElement,
  Action,
  Select,
  InputNumber,
  Input,
  ButtonContainer,
  Header,
  Small,
  Wrapper,
  Summary,
  List,
  ListItem,
  Name,
  RightValue,
  OTPContainer,
  Title,
  OTPWrapper,
  Label,
  ErrorMessage,
  DoneContainer,
  DoneFrame,
  DoneText,
  PmDescription,
  FooterParagraph,
  Modal,
  LinkText,
  CheckBokContainer,
} from "./styles/invest";

import { Text } from "src/utils/styledComponents/typography";

import { Typography, InputProps, InputNumberProps, SelectProps } from "antd";
import { calculateFee, formatMoney } from "src/utils/number";
import DisclaimerModal from "src/components/Modal";

import {
  FINAMAZE_PREMIUM_COMMISSION,
  FINAMAZE_VIP_COMMISSION,
} from "src/utils/constants/commissions";

import { ERiskSolutions } from "src/utils/products";
import { getExecutionTime as getPrivateEquetyExecutionTime } from "src/pages/Invest/PrivateMarket/utils/ExecutionTime";

import { Input as StyledInput } from "src/components";

import { numberWithCommasWithoutRounding } from "src/utils/numberWithCommas";
import { colors } from "src/theme/colors";

const Invest = ({ children, ...rest }) => {
  return <Container {...rest}>{children}</Container>;
};

Invest.Info = function InvestInfo({
  children,
}: {
  children?: React.ReactNode;
}) {
  return <Info>{children}</Info>;
};

Invest.Form = function InvestForm({
  onSubmit,
  children,
  ...restProps
}: {
  onSubmit?: (values: any) => void;
  onReset?: (values: any) => void;
  children?: React.ReactNode;
}) {
  return (
    <Form {...restProps} onSubmit={onSubmit}>
      {children}
    </Form>
  );
};

Invest.Element = function InvestElement({ children }) {
  return <InputElement className="InputElement">{children}</InputElement>;
};

Invest.RadioElement = function InvestRadio({ children }) {
  return <RadioElement>{children}</RadioElement>;
};

Invest.Action = function InvestAction({ children, ...rest }) {
  return <Action {...rest}>{children}</Action>;
};

const { Option, OptGroup } = Select;

interface Props extends SelectProps<any> {
  name: string;
  id?: string;
  label?: string;
  onSelectionChange: (value: SelectValue, name: string) => void;
  group?: boolean;
  items?: any[];
  defaultValue?: any;
  error?: any;
  color?: any;
}

Invest.Select = function InvestSelect({
  label,
  onSelectionChange,
  group,
  items,
  name,
  error,
  defaultValue,
  id,
  ...rest
}: Props) {
  return (
    <>
      <Label htmlFor={id}>
        <Text type={error ? "danger" : null}>{label}</Text>
        {error && <Text type="danger">{error}</Text>}
      </Label>
      <Select
        error={error}
        id={id}
        {...rest}
        style={{ width: "100%" }}
        defaultValue={defaultValue}
        onChange={(value) => onSelectionChange(value, name || id)}
      >
        {group
          ? items.map(({ options, label }, i) => (
              <OptGroup key={i} label={label}>
                {options.map(({ label, value }) => (
                  <Option key={value} value={value}>
                    {label}
                  </Option>
                ))}
              </OptGroup>
            ))
          : items.map(({ label, value }) => (
              <Option key={value} value={value}>
                {label}
              </Option>
            ))}
      </Select>
    </>
  );
};

interface InvestInputNumberProps extends InputNumberProps {
  name: string;
  label?: string;
  error?: string;
}

Invest.InputNumber = function InvestInputNumber({
  onChange,
  defaultValue,
  label,
  formatter,
  parser,
  name,
  error,
  id,
  color,
  ...rest
}: InvestInputNumberProps) {
  return (
    <>
      <Label color={color} htmlFor={id}>
        <Text type={error ? "danger" : null}>{label}</Text>
        {error && <Text type="danger">{error}</Text>}
      </Label>
      <InputNumber
        id={id}
        name={name}
        defaultValue={defaultValue}
        size="large"
        formatter={formatter}
        parser={parser}
        onChange={onChange}
        {...rest}
        color={color}
      />
    </>
  );
};

interface InvestInputProps extends InputProps {
  name: string;
  label?: string;
}

Invest.Input = function InvestInput({ name, label }: InvestInputProps) {
  return (
    <>
      <Typography.Text>{label}</Typography.Text>
      <Input name={name} size="large" placeholder="large size" />;
    </>
  );
};

Invest.Icon = function InvestIcon({
  src,
  color,
  size,
}: {
  src: string;
  color: "white" | "green" | "blue";
  size?: string;
}) {
  return <Icon size={size} color={color} src={src} />;
};

Invest.ButtonContainer = function InvestButton({ children, ...rest }) {
  return <ButtonContainer {...rest}>{children}</ButtonContainer>;
};

Invest.Modal = ({
  isModalVisible,
  handleOk,
  handleCancel,
  children,
  footer,
  title,
  closable = true,
  ...rest
}) => {
  return (
    <Modal
      maskStyle={{
        backdropFilter: "blur(10px)",
      }}
      centered
      closable={closable}
      title={title !== null && <Header>{title}</Header>}
      visible={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      width={"60%"}
      footer={footer}
      {...rest}
    >
      {children}
    </Modal>
  );
};

const DATE_FORMAT = "DD-MM-YYYY";

Invest.Summary = function InvestSummary({
  values,
  riskDetails,
  score,
  smartfolioFullObject,
  clientType,
}) {
  const [isPublicMarketDisclaimerVisible, setIsPublicMarketDisclaimerVisible] =
    useState(false);
  const [isCryptoDisclaimerVisible, setCryptoDisclaimerVisible] =
    useState(false);
  const managmentFees = () => {
    let commission: number;
    switch (values?.riskSolution) {
      case ERiskSolutions.fullExposure:
        commission = FINAMAZE_PREMIUM_COMMISSION;
        break;
      case ERiskSolutions.vip:
        commission = FINAMAZE_VIP_COMMISSION;
        break;
      // case ERiskSolutions.personalized:
      //   commission = FINAMAZE_PERSONALIZED_COMMISSION;
    }
    return numberWithCommasWithoutRounding(commission * values.amount);
  };

  const expectedNAV =
    ((riskDetails.currentLayerNav + values.amount) /
      (riskDetails.activePosionsNav + values.amount)) *
    100;
  return (
    <Summary>
      <Small>
        {process.env.REACT_APP_BANK_NAME || ""} Capital will add the
        corresponding Management Fees. <br />
        We will send you a copy of this summary via email{" "}
      </Small>
      {smartfolioFullObject?.market === "Crypto" ? (
        <LinkText onClick={() => setCryptoDisclaimerVisible(true)}>
          <Small>{"Cryptocurrency Investing Disclaimer"}</Small>
        </LinkText>
      ) : (
        ""
      )}
      {clientType === "Retail" ? (
        <LinkText onClick={() => setIsPublicMarketDisclaimerVisible(true)}>
          <Small>{"Public Market Terms & Conditions"}</Small>
        </LinkText>
      ) : (
        ""
      )}

      <Wrapper>
        <Typography.Title level={3}>{values?.portalName}</Typography.Title>
        <List>
          <ListItem>
            <Name>Amount to invest</Name>
            <RightValue>${formatMoney(values?.amount)}</RightValue>
          </ListItem>
          <ListItem>
            <Name>Order Date</Name>
            <RightValue>{moment().format(DATE_FORMAT)}</RightValue>
          </ListItem>
          <ListItem>
            <Name>Client Id</Name>
            <RightValue>{values?.clientId}</RightValue>
          </ListItem>
          <ListItem>
            <Name>Management Fees</Name>
            <RightValue>${managmentFees()}</RightValue>
          </ListItem>
          <ListItem>
            <Name>Layer - Class</Name>
            <RightValue>
              {riskDetails.currentLayer} - {riskDetails.currAssetClass}
            </RightValue>
          </ListItem>
          <ListItem>
            <Name>Risk Solution</Name>
            <RightValue>
              {smartfolioFullObject.riskSolution[values?.riskSolution]}
            </RightValue>
          </ListItem>
          <ListItem>
            <Name>Take Profit</Name>
            <RightValue color="#00C92F">
              {values?.takeProfit ? `+${values?.takeProfit}%` : "N/A"}
            </RightValue>
          </ListItem>
          <ListItem>
            <Name>Stop Loss</Name>
            <RightValue color="#DC3545">
              {values?.stopLoss ? `${values?.stopLoss}%` : "N/A"}
            </RightValue>
          </ListItem>
          <ListItem>
            <Name>Allocation: Suggested vs Post Execution </Name>
            <RightValue
              style={{
                color: `${getCurrentLayerPercentageColor(
                  expectedNAV,
                  score,
                  riskDetails.currentLayer,
                )}`,
              }}
            >
              {riskDetails.suggestedPercentage}% vs{" "}
              {Number(expectedNAV).toFixed(0)}%
            </RightValue>
          </ListItem>
          {new Date() < new Date("2021-12-13") && (
            <FooterParagraph>
              * Golden Jubilee Rebate to be credited to your balance on Dec 12th
              *
            </FooterParagraph>
          )}
        </List>
      </Wrapper>

      <DisclaimerModal
        title="FINAMAZE RISK DISCLOSURE FOR PRIME CLIENTS"
        onCancel={() => setIsPublicMarketDisclaimerVisible(false)}
        footer={[
          <div>
            <CheckBokContainer>
              <label>
                <input type="checkbox" disabled={true} defaultChecked={true} />I
                understand the risks, and I agree to these terms
              </label>
            </CheckBokContainer>
            <Disclaimer.Button disabled={true} key="customFooter">
              <Disclaimer.Frame>Accepted</Disclaimer.Frame>
            </Disclaimer.Button>
            ,
          </div>,
        ]}
        visible={isPublicMarketDisclaimerVisible}
      >
        <DisclaimerContainer disclaimer={"publicMarket"} clientType={""} />
      </DisclaimerModal>

      <DisclaimerModal
        title={`${
          clientType === "Professional"
            ? "Cryptocurrency Investing Disclaimer For Elite Clients"
            : "Cryptocurrency Investing Disclaimer For Prime Clients"
        }`}
        onCancel={() => setCryptoDisclaimerVisible(false)}
        footer={[
          <div>
            <CheckBokContainer>
              <label>
                <input type="checkbox" disabled={true} defaultChecked={true} />I
                understand the risks, and I agree to these terms
              </label>
            </CheckBokContainer>

            <Disclaimer.Button disabled={true} key="customFooter">
              <Disclaimer.Frame>Accepted</Disclaimer.Frame>
            </Disclaimer.Button>
          </div>,
        ]}
        visible={isCryptoDisclaimerVisible}
      >
        <DisclaimerContainer disclaimer={"crypto"} clientType={""} />
      </DisclaimerModal>
    </Summary>
  );
};

Invest.PESummary = ({ formik, onClick }) => {
  const { values, errors } = formik;
  const message =
    values.max_amount !== 0
      ? `(max: $${numberWithCommas(values.max_amount)})`
      : "";

  return (
    <Summary>
      <PmDescription>
        I acknowledge that this amount will be invested for a 10-year maximum
        term with up to two one-year extensions. I have reviewed the
        presentations of both {process.env.REACT_APP_BANK_NAME || ""} Capital "
        {values.smartfolio}" and "BECO Booster Fund III" and{" "}
        <b
          style={{
            color: colors.primary[400],
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={onClick}
        >
          I accept all terms and conditions.{" "}
        </b>
      </PmDescription>
      <Wrapper>
        <Typography.Title level={3}>{"Top VC Booster"}</Typography.Title>{" "}
        <List>
          <ListItem>
            <Name>Order Date</Name>
            <RightValue>{values.orderdate}</RightValue>
          </ListItem>

          <ListItem>
            <Name>Client ID</Name>
            <RightValue>{values.clientId}</RightValue>
          </ListItem>

          <ListItem>
            <Name>Management Fees</Name>
            <RightValue>${calculateFee(values.amount)}</RightValue>
          </ListItem>

          <ListItem>
            <Name>Class</Name>
            <RightValue>{values.class}</RightValue>
          </ListItem>

          <div
            style={{
              padding: "4px 8px",
            }}
          >
            <StyledInput.InputNumber
              id="amout"
              name="amount"
              label={`Amount to invest ${message}`}
              value={values.amount}
              formatter={(value) =>
                `$${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")
              }
              precision={2}
              error={errors.amount}
              onChange={(value) => {
                formik.setFieldValue("amount", value);
              }}
            />
          </div>
        </List>
      </Wrapper>
    </Summary>
  );
};

Invest.PrivateSummary = (props) => {
  const { values } = props;
  const { setModalsState, modalsVisibilityState } = props;

  return (
    <Summary>
      <PmDescription>
        By reserving my allocation, I acknowledge full understanding and I
        accept the{" "}
        <b
          style={{
            color: colors.primary[400],
            cursor: "pointer",
            textDecoration: "underline",
          }}
          onClick={() =>
            setModalsState({
              ...modalsVisibilityState,
              smartfolioAgreementReminderModal: true,
            })
          }
        >
          Terms of the “{values.smartfolio}” Smartfolio
        </b>
      </PmDescription>
      <Wrapper>
        <Typography.Title level={3}>{values.smartfolio}</Typography.Title>
        <List>
          <ListItem>
            <Name>Client Id</Name>
            <RightValue>{values.clientId}</RightValue>
          </ListItem>

          <ListItem>
            <Name>Class</Name>
            <RightValue>{values.class}</RightValue>
          </ListItem>

          <ListItem>
            <Name>Expected Amount to Be Invested</Name>
            <RightValue>${formatMoney(values?.amount)}</RightValue>
          </ListItem>

          <ListItem>
            <Name>Date of Allocation Reservation</Name>
            <RightValue>{moment().format(DATE_FORMAT)}</RightValue>
          </ListItem>

          <ListItem>
            <Name>
              Deadline to Debit Balance (After Confirmation of Allocation)
            </Name>
            <RightValue>{getPrivateEquetyExecutionTime()}</RightValue>
          </ListItem>

          <ListItem>
            <Name>
              {process.env.REACT_APP_BANK_NAME || ""} Capital Annual Management
              Fees <b>{" (0.50%)"}</b>
            </Name>
            <RightValue color={colors.primary[500]}>
              ${`${calculateFee(values.amount)}`}
            </RightValue>
          </ListItem>
        </List>
      </Wrapper>
    </Summary>
  );
};

Invest.OTP = function InvestOTP({ onChange, onBlur, error, value }) {
  return (
    <OTPContainer>
      <Small>
        As an extra measure of security, we would like to re-verify your
        identity.
      </Small>
      {error && (
        <ErrorMessage
          message="Error"
          description={error}
          type="error"
          showIcon
        />
      )}
      <Wrapper>
        <Title>
          We just sent your OTP via both Email and SMS. Please verify your
          account to proceed.
        </Title>
        <OTPWrapper>
          <InputMask
            label="OTP"
            name="otpCode"
            id="otpCode"
            onChange={onChange}
            onBlur={onBlur}
            type="text"
            alwaysShowMask
            // mask="9   9   9   9   9   9"
            formatChars={{
              "9": "[0-9]",
            }}
            value={value}
          ></InputMask>
        </OTPWrapper>
      </Wrapper>
    </OTPContainer>
  );
};

Invest.StopLoss = ({ stopLoss }) => {
  return (
    <Summary>
      <Title>
        We recommend a Stop Loss value no lower than
        <RightValue style={{ color: "#2477F6" }}> 15%</RightValue>
        <br />
        Are you sure you want to continue?
      </Title>
      <Title>
        <Title>
          <b>Stop Loss =</b>{" "}
          <RightValue style={{ color: "#DC3444" }}> -{stopLoss}%</RightValue>
        </Title>
      </Title>
    </Summary>
  );
};

Invest.Done = function InvestDone() {
  return (
    <DoneContainer>
      <img src={OrderSuccess} alt="" />
      <Typography.Text type="success">
        <DoneFrame>
          <Icon src={CheckMark} />{" "}
          <DoneText>Your order was placed successfuly</DoneText>
        </DoneFrame>
      </Typography.Text>
      <Small>
        {process.env.REACT_APP_BANK_NAME || ""} Capital will add the
        corresponding Management Fees.
        <br />
        The total amount will be reserved until the order is executed or
        canceled.
      </Small>
      <DoneFrame width="70%">
        <Typography.Text>
          {process.env.REACT_APP_BANK_NAME || ""} Capital will execute this
          order at the next 6:45 pm to 7:45 pm UAE (UTC+4) time-window,
          <br />
          Monday through Friday.
          <br /> Orders placed after 6:45 pm will process in the following
          window. You can cancel it until then.
        </Typography.Text>
      </DoneFrame>
    </DoneContainer>
  );
};
Invest.PrivateDone = () => {
  return (
    <DoneContainer>
      <img src={OrderSuccess} alt="" />
      <Typography.Text type="success">
        <DoneFrame>
          {/* <Icon src={CheckMark} /> */}
          <DoneText>
            Your Allocation Reservation has been successfully received
          </DoneText>
        </DoneFrame>
      </Typography.Text>
      <DoneFrame width="80%">
        <Typography.Text>
          From your Dashboard, you may confirm, cancel or amend the amount
          anytime before automatic execution on{" "}
          <span style={{ color: "#2478f6" }}>
            {getPrivateEquetyExecutionTime()}.
          </span>
          <br />
          Your balance will not be debited before then.
        </Typography.Text>
      </DoneFrame>
    </DoneContainer>
  );
};

Invest.PEDone = () => {
  return (
    <DoneContainer>
      <img src={OrderSuccess} alt="" />
      <Typography.Text type="success">
        <DoneFrame>
          <DoneText>Your order was placed successfuly</DoneText>
        </DoneFrame>
      </Typography.Text>
      <DoneFrame width="70%">
        <Typography.Text>
          {process.env.REACT_APP_BANK_NAME || ""} Capital will add the
          corresponding Management Fees.
          <br />
          The total amount will be reserved until the order is executed.{" "}
        </Typography.Text>
      </DoneFrame>
    </DoneContainer>
  );
};

Invest.PECanceled = () => {
  return (
    <DoneContainer>
      <img src={ExitSuccessIcon} alt="" />
      <Typography.Text type="success">
        <DoneFrame>
          <DoneText>Your order has been canceled successfuly</DoneText>
        </DoneFrame>
      </Typography.Text>
    </DoneContainer>
  );
};

export default Invest;
