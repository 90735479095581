import React, { useEffect, Suspense, lazy, useMemo } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import PrivateRoute from "src/utils/PrivateRoute";
import LogOut from "./utils/LogOut";
import { loadUser } from "src/store/Client";
import * as ROUTES from "./utils/constants/routes";
import Loading from "src/components/Loading";
import HeaderItems from "src/components/HeaderItems";
import useClient from "src/store/hooks/useClient";
import FinaLayout from "./components/FinaLayout";
import { useInvestments, useCustomInvestments } from "src/store/hooks";
import BrokerageOrders from "./pages/Brokerage/orders";
import { useAnalytics } from "./hooks/useAnalytics";
import GoalBasedPredictions from "./pages/GoalBased/predictions";
import NiceModal from "@ebay/nice-modal-react";
import PrivateSmartfolio from "./pages/PrivateSmartfolio";

const NewInvestPublic = lazy(
  () => import("./pages/newInvestPage/PublicMarket"),
);
const NewInvestPrivate = lazy(
  () => import("./pages/newInvestPage/PrivateMarket"),
);

const Dashboard = lazy(() => import("./pages/Dashboard"));
const Withdraw = lazy(() => import("./pages/Withdraw"));
const LeanDeposit = lazy(() => import("./pages/LeanDeposit"));
const Settings = lazy(() => import("./pages/Settings"));
const TempLogin = lazy(() => import("./pages/TempLogin"));
const Invest = lazy(() => import("./pages/Invest"));

const Brokerage = lazy(() => import("./pages/Brokerage"));
const BrokerageDetails = lazy(() => import("./pages/Brokerage/details"));

// const OurSmartfolios = lazy(() => import("./pages/OurSmartfolios"));
const InvestmentDetails = lazy(() => import("./pages/InvestmentDetails"));
const NotFound = lazy(() => import("./pages/Error/NotFound"));
const Error = lazy(() => import("./pages/Error"));
const FindSmartfolio = lazy(() => import("./pages/FindSmartfolio"));
const Markets = lazy(() => import("./pages/Markets"));
const GoalBased = lazy(() => import("./pages/GoalBased"));
const SingleSmartfolio = lazy(() => import("./pages/SingleSmartfolio/"));
const SmartfolioAnalytics = lazy(() => import("./pages/SmartfolioAnalytics"));

const App: React.FC = () => {
  useAnalytics();
  const dispatch = useDispatch();
  const location: any = useLocation();
  const selectedSmartfolio: string | undefined = useMemo(
    () => location.state?.name,
    [location.state],
  );

  const CurrentClient = useClient();
  const user = {
    ...CurrentClient,
    isAuthenticated:
      CurrentClient.data && !CurrentClient.isLoading && CurrentClient.isSuccess,
  };
  // useInvestments();
  // useCustomInvestments();
  // NOTE: we're using this for the temp login
  useEffect(() => {
    dispatch(loadUser());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (user.isLoading) return <Loading />;

  // this line will prevent browser back functionality
  // only authenticate clients can see the dashboard
  if (!user.isAuthenticated) return LogOut();

  return (
    <FinaLayout>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Redirect exact from="/" to={ROUTES.DASHBOARD} />

          <PrivateRoute user={user} path={ROUTES.DASHBOARD}>
            <Dashboard user={user} />
          </PrivateRoute>

          {/* <PrivateRoute exact user={user} path={ROUTES.OURSMARTFOLIOS}>
            <OurSmartfolios user={user} />
          </PrivateRoute> */}

          <PrivateRoute exact user={user} path={ROUTES.INVEST}>
            <Invest user={user} selectedSmartfolioName={selectedSmartfolio} />
          </PrivateRoute>

          <PrivateRoute exact user={user} path={ROUTES.MARKETS}>
            <Markets />
          </PrivateRoute>

          <PrivateRoute exact user={user} path={ROUTES.GOAL_BASED}>
            <GoalBased />
          </PrivateRoute>

          <PrivateRoute exact user={user} path={ROUTES.GOAL_PREDICTIONS}>
            <GoalBasedPredictions />
          </PrivateRoute>

          <PrivateRoute exact user={user} path={ROUTES.BROKERAGE}>
            <Brokerage user={user} />
          </PrivateRoute>

          <PrivateRoute exact user={user} path={ROUTES.BROKERAGE_DETAILS}>
            <BrokerageDetails user={user} />
          </PrivateRoute>

          <PrivateRoute
            exact
            user={user}
            path={`${ROUTES.BROKERAGE_ORDERS}/:ticker_id`}
          >
            <BrokerageOrders user={user} />
          </PrivateRoute>

          <PrivateRoute user={user} path={ROUTES.DEPOSIT}>
            <LeanDeposit user={user} />
            {/* <NotFound /> */}
          </PrivateRoute>

          <PrivateRoute exact user={user} path={ROUTES.SINGLESMARTFOLIO}>
            <SingleSmartfolio />
          </PrivateRoute>
          <PrivateRoute exact user={user} path={ROUTES.PRIVATESMARTFOLIO}>
            <PrivateSmartfolio />
          </PrivateRoute>
          <PrivateRoute exact user={user} path={ROUTES.GOAL_BASED_SMARTFOLIO}>
            <SmartfolioAnalytics />
          </PrivateRoute>

          <PrivateRoute user={user} path={ROUTES.WITHDRAW}>
            <Withdraw />
          </PrivateRoute>

          <PrivateRoute user={user} path={ROUTES.SETTINGS}>
            <Settings user={user} />
          </PrivateRoute>

          <PrivateRoute user={user} path={`${ROUTES.INVESTMENT_DETAILS}/:id`}>
            <InvestmentDetails />
          </PrivateRoute>

          <PrivateRoute user={user} path={`${ROUTES.OURSMARTFOLIOS}`}>
            <FindSmartfolio />
          </PrivateRoute>

          <PrivateRoute user={user} path={`${ROUTES.NEW_INVEST_PUBLIC}`}>
            <NewInvestPublic user={user} />
          </PrivateRoute>

          <PrivateRoute user={user} path={`${ROUTES.NEW_INVEST_PRIVATE}`}>
            <NewInvestPrivate user={user} />
          </PrivateRoute>
          <PrivateRoute user={user} path={`${ROUTES.NEW_INVEST_STRUCTURED}`}>
            <NewInvestPrivate user={user} />
          </PrivateRoute>

          <Route path={ROUTES.LOGIN} component={TempLogin} />
          <Route path={ROUTES.LOGOUT} component={LogOut} />
          <Route path={ROUTES.ERROR} render={(props) => <Error {...props} />} />
          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </FinaLayout>
  );
};

export default App;
