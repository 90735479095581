import React, { useState } from "react";
import NiceModal, { NiceModalHandler, useModal } from "@ebay/nice-modal-react";
import Modal from "src/components/Modal";
import styled from "styled-components";
import {
  Small,
  Wrapper,
  OTPContainer,
  Title,
  OTPWrapper,
} from "src/components/Invest/styles/invest";
import InputMask from "react-input-mask";
import { Button, Invest } from "src/components";
import checkIcon from "src/assets/check.svg";
import { useFormik } from "formik";
import { Row, Col } from "antd";
import { useTimer } from "./hooks";
import { validationSchema } from "./validationSchema";

import { theme } from "src/theme/theme";
const responsive = {
  span: 12,
  xs: 24,
  xl: 12,
  xxl: 12,
};

const Input = styled(InputMask)`
  &:focus-visible {
    outline: 2px solid ${theme.colors.primary[500]};
    border-radius: 3px;
  }
`;

export default NiceModal.create(
  ({
    resend,
    verify,
    onSuccess,
    onFailure,
  }: {
    resend?: () => Promise<any>;
    verify: (otp: string) => Promise<any>;
    onSuccess?: (data: any) => Promise<any>;
    onFailure?: (
      error: any,
      modalInstance: NiceModalHandler<Record<string, unknown>>,
    ) => Promise<any>;
  }) => {
    // States
    const [state, setState] = useState({
      isLoading: false,
      error: null,
      timerActive: false,
    });
    const [timeLeft, resetTimer] = useTimer(30, () =>
      setState((s) => ({ ...s, timerActive: false })),
    );
    const modal = useModal();
    const onCancel = () => modal.hide();

    const formik = useFormik({
      initialValues: {
        otp: "",
        error: null,
      },
      validationSchema,
      onSubmit: async (values) => {
        const otp = values.otp.replace(/\s+/g, "");
        try {
          const res = await verify(otp);
          if (onSuccess) onSuccess(res?.data);
          modal.hide();
          modal.remove();
        } catch (error) {
          formik.setFieldError(
            "otp",
            error?.data?.error?.message || "Error, Please try again !!!",
          );
          formik.setSubmitting(false);

          if (onFailure) onFailure(error.message, modal);
        }
      },
    });

    const resendOtp = async () => {
      setState({ ...state, isLoading: true, error: null });
      try {
        await resend();
        resetTimer();
        setState({ ...state, isLoading: false, timerActive: true });
      } catch (error) {
        setState({ ...state, isLoading: false, error: error.message });
      }
    };

    const _footer = () => [
      <div
        style={{
          width: "50%",
        }}
      >
        <Row gutter={[16, 16]}>
          {resend && (
            <Col {...responsive}>
              <Button
                key="back"
                outline={true}
                width="100%"
                onClick={resendOtp}
                disabled={state.timerActive || state.isLoading}
                isLoading={state.isLoading}
              >
                {state.timerActive ? `${timeLeft} seconds` : "Resend OTP"}
              </Button>
            </Col>
          )}
          <Col {...responsive}>
            <Button
              key="submit"
              onClick={formik.handleSubmit}
              width="100%"
              type="submit"
              disabled={!formik.isValid || formik.isSubmitting || !verify}
              loading={formik.isSubmitting}
            >
              <Invest.ButtonContainer
                style={{
                  color: "white",
                }}
              >
                Verify
                <Invest.Icon size="20px" color="white" src={checkIcon} />
              </Invest.ButtonContainer>
            </Button>
          </Col>
        </Row>
      </div>,
    ];

    return (
      <Modal
        title="OTP Verification"
        visible={modal.visible}
        onCancel={onCancel}
        afterClose={() => modal.remove()}
        footer={_footer()}
      >
        <OTPContainer>
          <Small>
            As an extra measure of security, we would like to re-verify your
            identity.
          </Small>
          <Wrapper>
            <Title>
              We just sent your OTP via both Email and SMS. Please verify your
              account to proceed.
            </Title>
            <OTPWrapper>
              <Input
                label="OTP"
                name="otp"
                id="otp"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                type="text"
                alwaysShowMask
                mask="9   9   9   9   9   9"
                formatChars={{
                  "9": "[0-9]",
                }}
                value={formik.values.otp}
              ></Input>
              {formik.errors.otp && formik.touched.otp ? (
                <div style={{ color: "red" }}>{formik.errors.otp}</div>
              ) : null}
            </OTPWrapper>
          </Wrapper>
        </OTPContainer>
      </Modal>
    );
  },
);
