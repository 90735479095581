import { message } from "antd";
import { useMutation } from "react-query";
import axiosInstance from "src/utils/axiosInstance";
import { SellI } from "../utils/interfaces";

const ADD_SELL_STOCK_ORDER_ENDPOINT = '/dashboard/direct-orders/sell'

type SellFormatData = {
    payload: SellI;
    otpCode: string | null
}

export const sellTicker = async ({ payload, otpCode }: SellFormatData) => {
    if (otpCode === null)
        await axiosInstance.post(`${ADD_SELL_STOCK_ORDER_ENDPOINT}?resend=true`, payload, { baseURL: 'v2' });
    else
        await axiosInstance.post(`${ADD_SELL_STOCK_ORDER_ENDPOINT}?otp=${otpCode}`, payload, { baseURL: 'v2' });
};

export const useSellStockOrder = () => {
    return useMutation(sellTicker, {
        onSuccess: () => {
        },
        onError: (error) => {
        },
    });
}