import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface MenuState {
  currentState?: string;
  isInvestClicked?: boolean;
  isSmartfolioSelected?: boolean;
}

const initialState: MenuState = {
  currentState: 'GoalAmount',
  isInvestClicked: false,
  isSmartfolioSelected: false,
};

const menuSlice = createSlice({
  name: 'menu',
  initialState,
  reducers: {
    setMenuState: (state, action: PayloadAction<MenuState>) => {
      state.currentState = action.payload.currentState;
    },
    setInvestActionState: (state, action: PayloadAction<MenuState>) => {
      state.isInvestClicked = action.payload.isInvestClicked;
    },
    setSmartfolioSelected: (state, action: PayloadAction<MenuState>) => {
      state.isSmartfolioSelected = action.payload.isSmartfolioSelected;
    },
  },
});

export const { setMenuState, setInvestActionState, setSmartfolioSelected } = menuSlice.actions;
export default menuSlice.reducer;
